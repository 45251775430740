.container {
  background: var(--station-panel-background);
  outline: 1px solid var(--accent);
  border-radius: 6px;
  gap: 4px;
  margin: 1px;
  display: flex;
  flex-direction: column;
  /* height: var(--station-panel-height); */
  height: var(--station-panel-height-dynamic);
  box-sizing:border-box;
  align-self: center;

  &.narrow {
    width: var(--radargram-width-dynamic);
  }
}

.loadingContainer {
  outline-width: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.defaultModeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.containerNoBorder {
  border-radius: 8px;
  gap: 4px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: var(--station-panel-background);
}

.ataContainer {
  width: var(--radargram-width-dynamic);
  margin-right: 2px;
  object-fit: contain;
  position: relative;
  justify-content: left;
  display: flex;
  box-sizing: border-box;
}

.ata {
  width: fit-content;
  object-fit: contain;
  position: relative;
  display: flex;
}

.core {
  display: flex;
  position: relative;
  max-width: var(--core-sample-width);
  height: 17px;
  width: 100%;

  &.narrow {
    width: var(--station-panel-narrow)
  }
}

.coreImage {
  object-fit: contain;
  width: 100%

}

.focusedOverlay {
  top: 0;
  left: 0;
  width: calc(100% - var(--border-width) * 2);
  height: calc(100% - var(--border-width) * 2);
  justify-content: end;
  position: absolute;
  border: var(--border-width), solid, var(--focused-border);
  pointer-events: none;
}

.imageContainer {
  contain: content;
  height: 100%;
  width: 100%;
}

.diagramImage {
  width: 100%;
  height: 100%;
  transition: 'transform 0.1s ease-out';
  touch-action: none;
}

.polargramImage {
  border-radius: 50%;
}

.pointer {
  pointer-events: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  background-image: url('/src/images/crosshair.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.scaleLabel {
  background: var(--focused-border);
  color: var(--dark-text);
  position: absolute;
  border-top-left-radius: 4px;
  bottom: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  padding: 2px;
  font-size: 10pt;
}

.titleBg {
  background: var(--content-background);
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  gap: 4px;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.titleLabel {
  color: #E5F3EBDE;
  font-size: 1.1rem;
  flex: 1;
}
.revolutionLabel {
  color: var(--darker-text);
  font-weight: bold;
  font-size: 0.8rem;
  margin-right: 8px;
}

.dataItemContainer {
  display: flex;
  flex-direction: column;
  width: 70px;
  justify-content: space-between;

}

.dataListContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
  margin-top: 8px;
}

.ataOverlay {
  position: absolute;
  left: 0;
  right: 0;
}

.ataOverlayLine {
  height: 1px;
  background-color: #00ff00;
  pointer-events: none;
  flex: 1;
  transform: translateY(-50%);
}

.ataOverlayText {
  font-size: 0.6rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 11px;
  font-size: 0.5rem;
  transform: translateY(-7px);
  padding: 1px;
  align-content: center;

}

.commentContainer {
  background: var(--content-background);
  padding: 8px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

}

.commentTitle {
  color: var(--darker-text);
  font-size: 0.9rem;
  font-weight: 700;
}

.commentText {
  color: var(--default-text);
  font-size: 0.9rem;
}

.dataTitle {
  color: var(--darker-text);
  font-size: 0.6rem;
  text-overflow: ellipsis;
  max-height: 40px;
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  width: max-content;
  height: max-content;
  border-radius: 4px;
  font-size: 0.5rem;
  padding: 2px;


}

.light {
  background: var(--highlight-background);
  border: 1px solid #29362B1F;
  color: var(--dark-text)
}

.dark {
  border: var(--box-border);
  background: var(--content-background);
  color: var(--default-text)
}

.labelMarker {
  width: 3px;
  height: 3px;
  background: #dcb749;
  border-width: 1px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  transform: translate(-2px, -2px);
  position: absolute;
  pointer-events: none;
}

.diagramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.coreSampleZoomArea {
  width: 500px;

}

.coreSampleZoomImage {
  contain: content;
  justify-content: center;
  align-items: center;
  height: 500px;
  display: flex;
}

.visibilitySettingContainer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: 15px;
  justify-content: end;
  position: absolute;
  gap: 2px;
}

.ataVisibilityContainer {
  padding: 4px;
}

.hideIcon {
  height: 24px;
  width: 24px;
}

.syncCheckboxContainer {
  background: #E5F3EB99;
  padding: 2px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  width: 36px;
  height: 14px;
  margin: 1px;
  padding: 0px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.checkbox {
  width: 12px;
  padding: 0px;
}

.checkboxLabel {
  font-size: 10px;
  color: var(--dark-text);
  user-select: none;
}

.showCoreSampleBarContainer {
  height: 9px;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 3px;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  background: var(--show-diagram-bar-background);
  opacity: 0;

  &.narrow {
    width: var(--radargram-width-dynamic)
  }

}

.showCoreSampleBarContainer:hover {
  opacity: 1;
}

.showCoreSampleBar {
  width: 24px;
  height: 2px;
  border-radius: 1px;
  background: var(--accent);
}

.showAtaBarContainer {
  width: 10px;
  border-radius: 3px;
  height: var(--radargram-width-dynamic);
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: var(--show-diagram-bar-background);
  opacity: 0;

}

.showAtaBarContainer:hover {
  opacity: 1;
}

.showAtaBar {
  height: 24px;
  width: 2px;
  border-radius: 1px;
  background: var(--accent);
}

.graphsContainer {
  display: flex;
  flex-direction: row;
  width: var(--station-panel-width);
  
}

.radargramPolargram {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  align-items: center;

  &.narrow {
    width: var(--radargram-width-dynamic);
  }
}

.redDot {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px; /* Adjust size as needed */
  height: 8px; /* Adjust size as needed */
  background-color: var(--indicator);
  border-radius: 50%; /* Makes it a circle */
}

.noteTooltip {
  position: absolute;
  left: 25px;
  transform: translateX(-100%);
  background-color: var(--background);
  color: var(--default-text);
  padding: 5px 10px;
  border-radius: 4px;
  transition: opacity 0.2s;
  font-size: 0.7rem;
  width: max-content;
  display: flex;
  max-width: 180px;
  max-height: 100px;
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */

}

.bookmarkContainer {
  position: relative;
  pointer-events: all;
  z-index: 10;
}

.scroll-horizontal {
  overflow-x: unset !important;
  overflow-y: auto !important;
}