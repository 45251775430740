.container {
  display: flex;
  height: 100%;
  flex: 1;
  padding: 20px;
  background: var(--background);
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.stationPanelContainer {
  display: flex;
  flex: 3;
  justify-content: center;
}
.controlContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.formGroup {
  margin-top: 30vh;
  width: 220px;
  align-content: center;
  gap: 16px;
  align-self: center;
}

.exitContainer {
  display: flex;
  justify-content: end;
}