

.container {
    background: var(--content-background);
    height: auto;
    min-width: 230px;
    width:  230px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.content {
    width: auto;
    flex: 1;
    overflow-y: auto;
    padding: 12px;
    display: flex;
    gap: 8px;
    flex-direction: column;
}
.title {
    font-weight: bold;
    color: #E5F3EBDE;
    font-size: 1.1rem;
    padding-bottom: 8px;
}
.label {
    color: #E5F3EBDE;
    font-size: 0.8rem;
    width: -webkit-fill-available;
    margin-left: 8px;

    &.bold {
        font-weight: bold;
    }
}

.exportContainer {
    padding: 42px 12px 12px 12px;
    background-color: var(--header-background);
    
}
.exportButton {
    background: var(--accent);
    border: 0;
    color: var(--primary-button-text);
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;

    &.disabled {
        background: var(--focused-border);
        pointer-events: none;
    }
}

.rowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkboxContainer {
    width: -webkit-fill-available;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.horizonCoord {
        padding-left: 24px;
    }
}