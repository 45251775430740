body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
:root {
  --station-panel-height: 768px;
  --header: 40px;
  --paging-height: 70px;
  --station-panel-height-dynamic: calc(100vh - var(--header) - var(--paging-height) - 25px);
  --station-panel-width: calc(var(--radargram-width) + var(--ata-width));
  --station-panel-narrow: calc(var(--radargram-width-dynamic) + var(--ata-width-narrow));
  --station-title-height: 70px;
  --station-metrics-height: 123px;
  --ata-width-narrow: 12px;
  --radargram-width: 275px;
  --core-sample-height: 18px;
  --radargram-width-dynamic: calc((var(--station-panel-height-dynamic) - var(--station-metrics-height) - var(--station-title-height) - var(--core-sample-height) - 8px)/2 );
  --radargram-width-focus: calc((var(--station-panel-height-dynamic) - var(--station-metrics-height) - var(--station-title-height) - 4px));
  --ata-width: 113px;
  --border-width: 3px;
  --core-sample-width: calc(var(--radargram-width) + var(--ata-width));
  --label-list-width: 230px;
  --content-width: calc(100vw - var(--label-list-width) - 16px);
  --content-height: calc(100vh - var(--header))
}