:root {
    --background: #1A1E1B;
    --content-background: #3A403C;
    --header-background: #2A2F2C;  
    --station-panel-background: #000; 
    --accent: #68DDD2; 
    --default-text: #E5F3EBDE;
    --darker-text: #E5F3EB99;
    --secondary-button: #BCCFBE29;
    --dark-background-300: #2A2F2C;

    --focused-border: #E5F3EB61;
    --dark-text: #14261BDE;
    --primary-button-text: #14261BDE;

    --box-border: 1px solid #CCDCD029;

    --input-background:  #BCCFBE14;
    --highlight-background: #ECF4EE;

    --switch-thumb-off: #14261BDE;
    --switch-thumb-on: #14261BDE;

    --switch-off-background: #E5F3EB99;

    --show-diagram-bar-background: #CCDCD029;

    --indicator: #FF6E45;
    --delete-warning: #FF6E45;
    
    --pagination-border-visible: #515954;
    --pagination-border-selected: #CFDCD4;
    --pagination-border-invisible: #212321; 
  }
 