.container {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.pagination {
  justify-content: center;
  display: flex;
}
.paginationContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edgeLabel {
  color: var(--accent);
  font-size: 0.5rem;
  background: none;
  border: none;
}

.paginationItem {
  height: 30px;
  width: 20px;
  padding: 3px;
  align-self: center;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-content: center;
  border-radius: 8px;
  color: var(--default-text);
  font-size: 0.7rem;

  &.visible {
    background: var(--content-background);
    border: 1px solid var(--pagination-border-visible);
    color: var(--default-text);
  }

  &.invisible {
    background: #000;
    border: 1px solid var(--pagination-border-invisible);
    color: var(--darker-text);
  }


  &.selected {
    border: 1px solid var(--pagination-border-selected);

  }
}

.ellipsis {
  height: auto;
  color: var(--default-text);
}

.arrow {
  background: transparent;
  border: none;
  color: var(--default-text);
}

.pageButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
}
.ruler {
  width: 1px;
  background:  var(--focused-border);

  &.long {
    height: 7px;
  }
  &.short {
    height: 3px;
  }
  &.highlighted {
    background: var(--default-text)
  }
}

.rulerValue {
  font-size: 0.5rem;
  line-height: 15px;
  color: var(--focused-border);

  &.highlighted {
    color: var(--pagination-border-selected)

  }
}

.rulerContainer {
  height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.focusView {
  background: var(--accent);
  border: 0;
  color: var(--primary-button-text);
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.7rem;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 110px;
}