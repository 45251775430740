
.ProjectNavigation {
  background-color: var(--background);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.loadingContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
}

.content {
  display: flex;
  height: 96vh;
  flex: 1;
}

.scrollableArea {
  width: 100%;
  flex-direction: row;
  display: flex;
  gap: 8px;
  overflow-x: hidden;
  padding: 2px;
}

.focusModeContainer {
  position: absolute;
  width: -webkit-fill-available;
  overflow: hidden;
  z-index: 5;
  height: -webkit-fill-available;
}