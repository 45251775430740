


.dropdownButton {
  background: var(--input-background)  !important;
  border: var(--box-border)  !important;
  border-radius: 6px  !important;
  color: var(--default-text)  !important;
  height: 34px  !important;
  justify-content: space-between  !important;
  font-size: 0.7rem  !important;
  text-transform: none !important;
}

.arrowContainer {
  padding-right: 4px;
  font-size: 0px !important;
}

.optionTextBold {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--default-text);
  margin-left: 4px;
}

.optionText {
  font-size: 0.8rem;
  color: var(--default-text);
  margin-left: 6px;
}