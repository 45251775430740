.full-width {
    width: 100%
}

.full-height {
    height: 100%
}

.flex1 {
    flex: 1
}
.gap-row-sm {
    width: 8px;
}

.gap-row-mid {
    width: 16px;
}

.gap-column-mid {
    height: 16px;
}

.gap-column-sm {
    height: 8px;
}

.flex {
    display: flex;
}

.flex-auto {
    display: flex;
    flex: auto
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.primary-button {
    background-color: var(--accent);
    border: 0;
    color: var(--primary-button-text);
    padding: 6px 12px;
    border-radius: 5px;
    width: fit-content;
}
.position-relative {
    position: relative;
}
.hide {
    visibility: "hidden";
}
.center-vertical {
    align-items: center;
    display: flex;
}

.custom-slider {
    & .MuiSlider-thumb {
        background-color: #E5F3EBDE;
        
        &:hover,  &.Mui-focusVisible {
            box-shadow: 0 0 2px 0px rgba(0, 0, 0, 1);
        };

        &:focus, &.Mui-active {
            box-shadow: none;
        }
        
    };
    & .MuiSlider-track {
        height: 8px;
        background-color: var(--accent);
        border: none;
    };
    & .MuiSlider-rail {
        opacity: 1;
        background: #525B54;
        height: 8px;
    };
    &.MuiSlider-trackInverted {
        & .MuiSlider-track {
            opacity: 1;
            background-color: #525B54;

        }
        & .MuiSlider-rail {
            opacity: 1;
            background-color: var(--accent);
        }
    };
}

.flip-horizontal {
    transform: scaleX(-1);
}

.MuiList-root {
    background: var(--header-background);
    border: none;
    min-width: 82px;
    max-height: 240px;
}

.MuiPaper-root {
    background: var(--content-background);
}

::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: var(--content-background); /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb on hover */
}

* {
    scrollbar-width: thin; /* Makes the scrollbar thin */
    scrollbar-color: #888 var(--content-background); /* Thumb color and track color */
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

.no-scrollbars{
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    user-select: none;
  
    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      pointer-events: none;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent;
      pointer-events: none;
      width: 1px;
      height: 1px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      pointer-events: none;
      width: 1px;
      height: 1px;
    }
}

.scroll-container {
    overflow: auto; /* Allows scrolling */
    scrollbar-width: none; /* For Firefox: hides scrollbar */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

.scroll-container::-webkit-scrollbar {
    display: none;
}

.horizontal-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    overflow: unset;
}
