.container {
  display: flex;
  height: fit-content;
  flex: 1;
  background: var(--background);
  align-items: center;
  padding-top: 24px;
}

.stationPanelContainer {
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.controlContainer {
  display: flex;
  flex-direction: column;
  height: var(--station-panel-height-dynamic);
}

.formGroup {
  margin-top: 30vh;
  width: 220px;
  align-content: center;
  gap: 16px;
  align-self: center;
}

.exitContainer {
  display: flex;
  justify-content: end;
}

.panelSelectionContainer {
  display: flex;
  flex-direction: column;
  background: var(--dark-background-300);
  width: var(--core-sample-width);
  min-width: var(--core-sample-width);
  border-radius: 6px;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 24px;
  /* height: var(--station-panel-height); */
  height: var(--station-panel-height-dynamic);
  box-sizing:border-box;

  &.narrow {
    width: var(--radargram-width-dynamic);
  }
}

.border {
  height: 1px;
  width: 100%;
  background: #515954;
}

.selectCardTitle {
  color: #fff;

}

.panelSelectionCard {
  width: 80%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background-color: var(--content-background);
  border-radius: 6px;
}

.panelSelectionBottom {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.panelSelectionSub {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.panelSelectionSubTitle {
  color: var(--darker-text);
  font-size: 0.7rem;
}

.panelSelectionSubValue {
  color: var(--default-text);
  font-size: 0.9rem;
}