.pointer {
  pointer-events: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transform: translate(-50%, -50%);
  background-image: url('/src/images/crosshair.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.tooltip {
  pointer-events: none;
  position: absolute;
  width: max-content;
  height: max-content;
  border-radius: 4px;
  font-size: 0.5rem;
  padding: 2px;
}

.light {
  background: var(--highlight-background);
  border: 1px solid #29362B1F;
  color: var(--dark-text)
}

.dark {
  border: var(--box-border);
  background: var(--content-background);
  color: var(--default-text)
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--radargram-width-dynamic);
  height: auto;
  aspect-ratio: 1/1;
  position: relative;
  object-fit: contain;
  contain: content;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding:2px
}

.diagramImage {
  width: 100%;
  height: 100%;
  transition: 'transform 0.1s ease-out';
  touch-action: none;
  object-fit: contain;
}

.focusedOverlay {
  top: 0;
  left: 0;
  width: calc(100% - var(--border-width) * 2);
  height: calc(100% - var(--border-width) * 2);
  justify-content: end;
  position: absolute;
  border: var(--border-width), solid, var(--focused-border);
  pointer-events: none;
}

.labelMarker {
  width: 3px;
  height: 3px;
  background: #dcb749;
  border-width: 1px;
  border: 1px solid #f1f1f1;
  border-radius: 50%;
  transform: translate(-2px, -2px);
  position: absolute;
  pointer-events: none;
}

.labelCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none
}
.scaleLabel {
  background: var(--focused-border);
  color: var(--dark-text);
  position: absolute;
  border-top-left-radius: 4px;
  bottom: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  padding: 2px;
  font-size: 10pt;
}