.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1px;
  background: var(--header-background);
  box-sizing: border-box;
  min-height: 40px;
}

.breadcrumbsContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.userIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
}