.container {
  border: var(--box-border);
  border-radius: 6px;
  background: var(--background);
  width: fit-content;
  height: fit-content;
  position: absolute;
  z-index: 6;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
}

.inputTitle {
  color: var(--default-text);
  font-size: 0.7rem;
}

.coordText {
  font-size: 0.7rem;
  flex: 1;
}

.inputField {
  background: var(--input-background);
  border: var(--box-border);
  border-radius: 6px;
  color: var(--default-text);
  padding: 8px;
  height: 34px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  &.editing {
    border: 1px solid var(--default-text);
  }
}

.actionRow {
  flex-direction: row;
  display: flex;
  justify-content:space-between;
  gap: 8px;
}

.createButton {
  background-color: var(--accent);
  border: 0;
  color: var(--primary-button-text);
  padding: 6px 12px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
}
.createButton:disabled {
  opacity: 0.5;
  cursor: default;
}

.cancelButton {
  background-color: transparent;
  border: none;
  color: var(--accent);
  width: fit-content;
  cursor: pointer;
}

.deleteButton {
  margin-top: 16px;
  border: 1px solid var(--delete-warning);
  width: fit-content;
  background-color: transparent;
  color: var(--delete-warning);
  border-radius: 5px;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
