
.container {
  background: var(--secondary-button);
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  padding: 1;
  justify-content: center;
  align-items: center;
  height: 26px;
}

.arrow {
  background: none;
  border: none;
  padding: 6px;
  align-items: center;
  display: flex;
  width: 16px;
  height: 16px;
  cursor: pointer;
}


.label {
  color: var(--default-text);
  font-size: 0.8rem;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}
