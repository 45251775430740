

.container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--accent);
  border-radius: 6px;
}
.optionContainer {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-top: 1px solid var(--accent);

}
.filterHeaderContainer {
  flex-direction: row;
  display: flex;
  cursor: pointer;
}
.filterTitle {
  font-size: 0.8rem;
  color: var(--accent);
  padding: 8px;
  margin-left: 8px;
  flex: 1;
}
.filterIcon {
  border-left:  1px solid var(--accent);
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  padding: 6px;
}
 
.optionTitle {
  color: var(--default-text);
  font-weight: bold;
  font-size: 12px;
}

.sliderValue {
  color: var(--darker-text);
  font-size: 0.7rem;
}
.sliderContainer {
  padding-left: 8px;
  padding-right: 8px;
}


.checkboxLabel {
  color: var(--default-text);
}

.showResult {
  background: var(--accent);
  padding: 8px;
  border-width: 0px;
  color: var(--dark-text);
  cursor: pointer;
}

.cuurentFilterList {
  display: flex;
  border-top: 1px solid var(--accent);
  padding: 4px;
  gap: 4px;
  flex-flow: wrap;
}

.removeFilterButton {
  width: fit-content;
  background: var(--accent);
  color: var(--dark-text);
  border-radius: 6px;
  border: none;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  flex-shrink: 0;
}

.listContainer {
  flex: 1;
  gap: 4px;
  display: flex;
  flex-flow: wrap;
}

.clearButton {
  border: none;
  background: none;
  padding: 2px;
  color: var(--darker-text);
  font-size: 0.8rem;
  width: fit-content;
  height: fit-content;
}

.labelOnlyToggle {
  justify-content: left;
  gap: 8px;
  margin-top: 16px;
}