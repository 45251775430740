
.content {
  display: flex;
  width: var(--content-width);
  border: none;
}

.scrollableArea {
  width: 100%;
  flex-direction: row;
  display: flex;
  gap: 8px;
  overflow-x: hidden;
  padding: 2px;
  height: -webkit-fill-available;
  border: none;
}

.focusModeContainer {
  position: absolute;
  overflow: hidden;
  z-index: 5;
  height: auto;
  width: var(--content-width);

}